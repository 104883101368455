import { useQuery } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { NotificationSettings } from '../types';

const getNotificationSettings = async (): Promise<NotificationSettings> => {
  const { data } = await axios.get('/notification-settings');
  return data;
};

export const useNotificationSettings = () => {
  return useQuery({
    queryKey: ['notification-settings'],
    queryFn: getNotificationSettings,
  });
};
