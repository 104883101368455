import { FC } from 'react';

import { Avatar, Group, NavLink, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

import { useUser } from '@features/users/api/getUser';
import { PRIVATE_ROUTES } from '@routes/enums';
import { getInitials } from '@utils/nameUtil';

export const UserAccountNav: FC = () => {
  const { data: user } = useUser();

  if (!user) return null;

  const initials = getInitials(user.firstName, user.lastName);

  return (
    <NavLink
      component={Link}
      to={PRIVATE_ROUTES.ACCOUNT}
      active={location.pathname === PRIVATE_ROUTES.ACCOUNT}
      px="xs"
      label={
        <Group gap="xs">
          <Avatar size="sm" radius="xl" src={user.profilePictureUrl} color="gray">
            {initials ?? ''}
          </Avatar>
          <Text size="sm">
            {user.firstName} {user.lastName}
          </Text>
        </Group>
      }
    />
  );
};
