import { FC } from 'react';

import { Group, Stack, TextInput } from '@mantine/core';

import { useUser } from '@features/users/api/getUser';

export const UserInfoForm: FC = () => {
  const { data: userData } = useUser();

  return (
    <Stack gap="md">
      <Group grow>
        <TextInput label="First Name" value={userData?.firstName ?? '-'} readOnly disabled variant="filled" />
        <TextInput label="Last Name" value={userData?.lastName ?? '-'} readOnly disabled variant="filled" />
      </Group>
      <TextInput label="Phone Number" value={userData?.phoneNumber ?? '-'} readOnly disabled variant="filled" />
    </Stack>
  );
};
