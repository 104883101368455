import { DateTime } from 'luxon';

export interface LinkInfo {
  label: string;
  to: string;
}

export interface GooglePlaceInfo {
  placeId: string;
  description: string;
}

export const DayOfWeek = {
  Monday: '1', // 1 is Monday
  Tuesday: '2',
  Wednesday: '3',
  Thursday: '4',
  Friday: '5',
  Saturday: '6',
  Sunday: '7', // 7 is Sunday
} as const;

export type DayOfWeek = (typeof DayOfWeek)[keyof typeof DayOfWeek];

export interface TimeWindowResponse {
  start: string;
  end: string;
}

export interface TimeWindow {
  start: DateTime;
  end: DateTime;
}

// prettier-ignore
export const Hour =  ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', 
  '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', 
  '20', '21', '22', '23'] as const;
export type Hour = (typeof Hour)[number];

// prettier-ignore
export const Minute = ['00' , '01' , '02' , '03' , '04' , '05' , '06' , '07' , '08' , '09', 
   '10' , '11' , '12' , '13' , '14' , '15' , '16' , '17' , '18' , '19', 
   '20' , '21' , '22' , '23' , '24' , '25' , '26' , '27' , '28' , '29', 
   '30' , '31' , '32' , '33' , '34' , '35' , '36' , '37' , '38' , '39', 
   '40' , '41' , '42' , '43' , '44' , '45' , '46' , '47' , '48' , '49', 
   '50' , '51' , '52' , '53' , '54' , '55' , '56' , '57' , '58' , '59'] as const;
export type Minute = (typeof Minute)[number];

export type TimeOfDay = `${Hour}:${Minute}` | '24:00';

export enum MeetingType {
  InPerson = 'in-person',
  Remote = 'remote',
}
