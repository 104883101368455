import React, { useEffect, useState } from 'react';

import { Autocomplete, CloseButton } from '@mantine/core';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

import { GooglePlaceInfo } from '@/types';

interface LocationAutocompleteProps {
  initialValue?: string;
  setLocation: (location: GooglePlaceInfo | null) => void;
  error?: string;
  label?: string;
  description?: string;
  required?: boolean;
}

export const LocationAutocomplete: React.FC<LocationAutocompleteProps> = (props) => {
  const { setLocation, error, label, description, required } = props;
  const places = useMapsLibrary('places');
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | null>(null);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);

  const [options, setOptions] = useState<GooglePlaceInfo[]>([]);
  const [value, setValue] = useState<string>(props.initialValue ?? '');

  useEffect(() => {
    if (!places) return;

    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [places]);

  if (!places || !autocompleteService || !sessionToken) {
    return null;
  }

  const handleChange = (value: string) => {
    const option = options.find((option) => option.description === value);

    if (!option) {
      setLocation(null);
      return;
    }
    setValue(option.description);
    setLocation(option);
    // We need to set a new session token once a selection has been made
    setSessionToken(new places.AutocompleteSessionToken());
  };

  const handleInputChange = async (newInputValue: string) => {
    setValue(newInputValue);
    // When the input changes, we need to make a call to places autocomplete service
    // to get a new set of options

    if (newInputValue === '') {
      setOptions([]);
      return undefined;
    }
    const token = new places.AutocompleteSessionToken();
    const { predictions } = await autocompleteService.getPlacePredictions({
      input: newInputValue,
      sessionToken: token,
    });

    const newOptions = predictions
      .filter((prediction) => prediction.place_id)
      .map((prediction) => ({
        placeId: prediction.place_id,
        description: prediction.description,
      }));
    setOptions(newOptions);
  };
  return (
    <Autocomplete
      error={error}
      placeholder="Enter establishment or address"
      description={description}
      /* Setting type search so password managers do not try to fill */
      type="search"
      /* Mantine throws an error if the data is not unique,
      in rare cases the descriptions can be the same between two options */
      data={[...new Set(options.map((option) => (typeof option === 'string' ? option : option.description)))]}
      onOptionSubmit={handleChange}
      onChange={handleInputChange}
      value={value}
      label={label}
      required={required}
      /* Having filter do nothing so that filtering is handled via google maps */
      filter={({ options }) => options}
      rightSection={
        <CloseButton
          size="sm"
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => {
            setValue('');
            setOptions([]);
            setLocation(null);
            setSessionToken(new places.AutocompleteSessionToken());
          }}
          aria-label="Clear value"
        />
      }
    />
  );
};
