import { FC } from 'react';

import { Button, Modal, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import { PRIVATE_ROUTES } from '@routes/enums';

import { useCreateCodewordTemplate } from '../api/createCodewordTemplate';

interface CreateCodewordTemplateModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

export const CreateCodewordTemplateModal: FC<CreateCodewordTemplateModalProps> = ({ opened, setOpened }) => {
  const navigate = useNavigate();
  const { mutate: createCodewordTemplate } = useCreateCodewordTemplate({
    onSuccess: (data) => {
      navigate(`${PRIVATE_ROUTES.CODEWORDS}/${data.id}`);
    },
  });
  const form = useForm({
    initialValues: {
      name: '',
    },
    validate: {
      name: (value) => (value.trim().length > 0 ? null : 'Name is required'),
    },
  });

  const handleSubmit = async (values: typeof form.values) => {
    createCodewordTemplate({
      name: values.name,
      description: '',
      keywords: [],
    });
  };
  return (
    <Modal opened={opened} onClose={() => setOpened(false)} title="Create Codeword Template">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          label="Name"
          description="Give your template a meaningful name"
          placeholder="e.g. Low Priority Meeting, First Intro Call"
          required
          {...form.getInputProps('name')}
        />
        <Button type="submit" mt="md">
          Continue
        </Button>
      </form>
    </Modal>
  );
};
