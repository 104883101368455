import { FC } from 'react';

import { Divider, Paper, Stack, Text } from '@mantine/core';
import { IconBell, IconUser } from '@tabler/icons-react';

import { SideContent } from '@components/SideContent';

import { SectionHeader } from './SectionHeader';
import { SettingsForm } from './SettingsForm';
import { UserInfoForm } from './UserInfoForm';

export const AccountsPage: FC = () => {
  return (
    <SideContent sideContent={<Text c="dimmed">Manage the global settings for your account.</Text>}>
      <Paper withBorder radius="md" p="xl">
        <Stack gap="lg">
          <SectionHeader
            icon={IconUser}
            title="Personal Information"
            description="Contact support to update your personal contact information"
          />
          <UserInfoForm />

          <Divider my="md" />

          <SectionHeader
            icon={IconBell}
            title="Notification Preferences"
            description="Control what types of notifications you receive and how you receive them"
          />
          <SettingsForm />
        </Stack>
      </Paper>
    </SideContent>
  );
};
