export enum AnalyticsEventName {
  PageView = 'Page View',
  LocationOverridesSynced = 'Location Overrides Synced',
  LocationOverrideManuallyAddedOrEdited = 'Location Override Manually Added',
  LocationOverrideDeleted = 'Location Override Deleted',
  PreferencesUpdated = 'Preferences Updated',
  CodewordTemplateCreated = 'Codeword Template Created',
  CodewordTemplateRead = 'Codeword Template Read',
  CodewordTemplateUpdated = 'Codeword Template Updated',
  CodewordTemplateDeleted = 'Codeword Template Deleted',
  WaitlistButtonClicked = 'Waitlist Button Clicked',
  NotificationSettingsUpdated = 'Notification Settings Updated',
}

type AnalyticsEventType = {
  [key in AnalyticsEventName]: { description: string; eventProperties: object };
};

export interface IAnalyticsEventSchema extends AnalyticsEventType {
  [AnalyticsEventName.PageView]: {
    description: 'When a user views a page';
    eventProperties: { pageName: string };
  };
  [AnalyticsEventName.LocationOverridesSynced]: {
    description: 'When location overrides are auto synced from calendar';
    eventProperties: { locationOverridesFound: number };
  };
  [AnalyticsEventName.LocationOverrideManuallyAddedOrEdited]: {
    description: 'When a location override is manually added';
    eventProperties: { locationOverrideId: string };
  };
  [AnalyticsEventName.PreferencesUpdated]: {
    description: 'When preferences are updated';
    eventProperties: {
      preference: string;
      isDefault: boolean;
    };
  };
  [AnalyticsEventName.CodewordTemplateCreated]: {
    description: 'When a new codeword template is created';
    eventProperties: { templateId: string; name: string };
  };
  [AnalyticsEventName.CodewordTemplateUpdated]: {
    description: 'When a codeword template is updated';
    eventProperties: { templateId: string; name: string };
  };
  [AnalyticsEventName.CodewordTemplateDeleted]: {
    description: 'When a codeword template is deleted';
    eventProperties: { templateId: string };
  };
  [AnalyticsEventName.WaitlistButtonClicked]: {
    description: 'When the waitlist button is clicked';
    eventProperties: {
      email: string;
      isQualified: boolean;
      numExternalMeetingsCreated: number;
    };
  };
  [AnalyticsEventName.NotificationSettingsUpdated]: {
    description: 'When a notification preference is updated';
    eventProperties: {
      setting: string;
    };
  };
}

// Define the main type that uses a generic type parameter to select the correct data type from the map
export type AnalyticsEvent<T extends keyof IAnalyticsEventSchema> = {
  type: T;
  data: IAnalyticsEventSchema[T]['eventProperties'];
};
