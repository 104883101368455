import { FC, useMemo, useState } from 'react';

import { Box, Group, Stack, Text, ThemeIcon, Collapse, UnstyledButton } from '@mantine/core';
import { IconCalendar, IconChevronDown, IconChevronRight } from '@tabler/icons-react';

import { AccountWithCalendars } from '../types';

import { AccountCompanyName } from './AccountCompanyName';
import styles from './AccountDetails.module.css';
import { CalendarDetails } from './CalendarDetails';

import { useUser } from '@features/users';
import { DeleteAccountButton } from './DeleteAccountButton';
interface AccountDetailsProps {
  account: AccountWithCalendars;
}

export const AccountDetails: FC<AccountDetailsProps> = ({ account }) => {
  const [showOtherCalendars, setShowOtherCalendars] = useState(false);
  const { data: user } = useUser();
  const blockbotEmail = import.meta.env.VITE_BLOCKBOT_EMAIL || null;
  const { writableCalendars, otherCalendars } = useMemo(() => {
    const sorted = account.calendars.sort((a, b) => {
      if (a.calendarId === account.email) {
        return -1;
      } else if (b.calendarId === account.email) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });

    return {
      writableCalendars: sorted.filter((cal) => ['writer', 'owner'].includes(cal.accessRole)),
      otherCalendars: sorted.filter((cal) => !['writer', 'owner'].includes(cal.accessRole)),
    };
  }, [account.calendars, account.email]);

  if (!user) return null;
  

  return (
    <Group gap="xs" align="flex-start">
      <Group display="flex" w="100%" justify="space-between">
        <ThemeIcon color="blue" variant="transparent">
          <IconCalendar />
        </ThemeIcon>
        <Text>{account.email !== blockbotEmail ? <DeleteAccountButton account={account} /> : null}</Text>
      </Group>

      <Stack gap="xs">
        <Text key={account.email}>{account.email}</Text>
        <AccountCompanyName account={account} />

        {/* Writable Calendars Section */}
        <Box>
          <Text size="sm" fw={700} mb="xs">
            Calendars
          </Text>
          {writableCalendars.map((calendar) => (
            <Box key={calendar.calendarId}>
              <CalendarDetails calendar={calendar} accountId={account.email} />
            </Box>
          ))}
        </Box>

        {/* Other Calendars Section */}
        {otherCalendars.length > 0 && (
          <Box>
            <UnstyledButton onClick={() => setShowOtherCalendars((o) => !o)} className={styles.otherCalendarsButton}>
              <ThemeIcon variant="transparent" size="sm">
                {showOtherCalendars ? <IconChevronDown size={16} /> : <IconChevronRight size={16} />}
              </ThemeIcon>
              <Text size="sm" fw={700}>
                Other calendars ({otherCalendars.length})
              </Text>
            </UnstyledButton>

            <Collapse in={showOtherCalendars}>
              <Box mt="xs">
                {otherCalendars.map((calendar) => (
                  <Box key={calendar.calendarId}>
                    <CalendarDetails calendar={calendar} accountId={account.email} />
                  </Box>
                ))}
              </Box>
            </Collapse>
          </Box>
        )}
      </Stack>
    </Group>
  );
};
