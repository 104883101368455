import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { AccountState, AccountWithCalendars } from '../types';
import { useUser } from '@features/users';


interface DeleteAccountParams {
  accountId: string;
}

export const deleteAccount = async (params: DeleteAccountParams) => {
  const { accountId } = params;
  await axios.delete(`/account/${encodeURIComponent(accountId)}/delete`);
  return true;
};

export const useDeleteAccount = () => {
  const { data: user } = useUser();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteAccount,
    onSuccess: (success, variables) => {
      if (success) {
        queryClient.setQueryData<AccountWithCalendars[]>(
          ['calendarsByAccounts', user?.id],
          (existingAccounts: AccountWithCalendars[] = []) => {
            return existingAccounts.map((account) => {
              if (account.email !== variables.accountId) return account;
              return { ...account, state: AccountState.DELETED };
            });
          },
        );
      }
    },
  });
};
