import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';

import { ErrorFallback } from '@components/ErrorFallback';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <Outlet />
    </Sentry.ErrorBoundary>
  );
};

export const AppRoutes = () => {
  return [{ path: '/', element: <App />, children: [...protectedRoutes, ...publicRoutes] }];
};
