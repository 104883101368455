export enum AccountState {
  ACTIVE = 'active',
  REFRESH_TOKEN_FAILED = 'refresh_token_failed',
  DELETED = 'deleted',
}

export interface Calendar {
  calendarId: string;
  name: string;
  isPrimary: boolean;
  isSelected: boolean;
  accessRole: 'owner' | 'reader' | 'writer';
}

export interface AccountWithCalendars {
  email: string;
  provider: string;
  calendars: Calendar[];
  scope: string[];
  state: AccountState;
  companyName: string | null;
  accountId: string;
}
