import { useEffect, useState } from 'react';

import { Button, Radio, Select, Stack, Text, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconDeviceDesktop } from '@tabler/icons-react';
import { z } from 'zod';

import { AnalyticsEventName, track } from '@features/analytics';
import { flipObject } from '@utils/flipObject';

import { useUpdatePreferences } from '../api/updatePreferences';
import { LinkType, PreferenceSettings, RemoteMeetingType } from '../types';

import { SettingsGrid } from './SettingsGrid';
import { ZoomAccountSelector } from './ZoomAccountSelector';

const DISPLAY_NAME_TO_LINK_TYPE: Record<string, LinkType> = {
  'Google Meet / Microsoft Teams': LinkType.GoogleMeet,
  'Zoom (Unique Link for Each Meeting)': LinkType.Zoom,
  'Custom Link (eg. Zoom Personal Meeting Room)': LinkType.CustomLink,
};

const LINK_TYPE_TO_DISPLAY_NAME = flipObject(DISPLAY_NAME_TO_LINK_TYPE);

export const VirtualMeetings = ({ initialPreferences }: { initialPreferences: PreferenceSettings }) => {
  const { mutate: updatePreferences, isPending: isUpdatingPreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({
        type: AnalyticsEventName.PreferencesUpdated,
        data: { preference: 'linkType', isDefault: initialPreferences.isDefault },
      });
      notifications.show({ message: 'Virtual meeting preferences updated', color: 'green', autoClose: 3000 });
      form.resetDirty();
    },
  });
  const [linkTypeFromState, setLinkTypeState] = useState<LinkType | null>(null);
  const [remoteMeetingType, setRemoteMeetingType] = useState<RemoteMeetingType | null>(null);
  const linkType = linkTypeFromState || initialPreferences.linkType;

  // Setting link type to the link type on preferences on initial load
  useEffect(() => {
    if (initialPreferences.linkType && !linkTypeFromState) {
      setLinkTypeState(initialPreferences.linkType);
    }
    if (initialPreferences.defaultRemoteMeetingType && !remoteMeetingType) {
      setRemoteMeetingType(initialPreferences.defaultRemoteMeetingType);
    }
  }, [initialPreferences.linkType, linkTypeFromState, initialPreferences.defaultRemoteMeetingType, remoteMeetingType]);

  const schema = z.object({
    link: z.string(),
    linkName: z.string().min(2, { message: 'Link name should have at least 2 letters' }),
  });

  const form = useForm({
    initialValues: {
      link: initialPreferences.link || '',
      linkName: initialPreferences.linkName || '',
    },
    validate: zodResolver(schema),
  });

  const handleLinkTypeChange = (value: string | null) => {
    if (!value || !initialPreferences) {
      // This should never happen, throwing an error
      throw new Error('Value or preferences not found');
    }

    const valueLinkType = DISPLAY_NAME_TO_LINK_TYPE[value];
    setLinkTypeState(valueLinkType);

    if (valueLinkType !== LinkType.CustomLink && valueLinkType !== LinkType.Zoom) {
      updatePreferences({ ...initialPreferences, linkType: valueLinkType, zoomAccountId: null });
      return;
    }
  };

  const handleSubmit = (values: typeof form.values) => {
    if (!linkType || !initialPreferences) {
      return;
    }
    updatePreferences({
      ...initialPreferences,
      link: values.link,
      linkName: values.linkName,
      linkType: linkType,
    });
  };

  const handleDefaultRemoteMeetingTypeChange = (value: RemoteMeetingType | null) => {
    if (!value || !initialPreferences) {
      // This should never happen, throwing an error
      throw new Error('Value or preferences not found');
    }

    setRemoteMeetingType(value);
    updatePreferences({ ...initialPreferences, defaultRemoteMeetingType: value });
  };

  const handleZoomAccountChange = (value: string | null, callback?: () => void) => {
    if (!value || !initialPreferences) {
      // This should never happen, throwing an error
      throw new Error('Value or preferences not found');
    }

    updatePreferences(
      { ...initialPreferences, linkType: LinkType.Zoom, zoomAccountId: value },
      { onSuccess: callback },
    );
  };

  if (!linkType) return null;

  return (
    <SettingsGrid
      Icon={IconDeviceDesktop}
      // eslint-disable-next-line max-len
      title="Default Virtual Meeting Format"
      // eslint-disable-next-line max-len
      description="Configure your default virtual meeting format."
      // eslint-disable-next-line max-len
      left={
        <>
          <Text c="dimmed">
            Unless specified otherwise in your email thread, Blockit will send a meeting invite with this virtual
            location.
          </Text>
          <Radio.Group
            value={remoteMeetingType}
            onChange={(value) => handleDefaultRemoteMeetingTypeChange(value as RemoteMeetingType)}
          >
            <Radio
              value={RemoteMeetingType.Video}
              disabled={false}
              label={
                <Stack style={{ marginBottom: '10px' }} gap="sm">
                  <Text lh={'normal'} span>
                    Video meeting
                  </Text>
                  <Select
                    data={Object.keys(DISPLAY_NAME_TO_LINK_TYPE)}
                    value={LINK_TYPE_TO_DISPLAY_NAME[linkType]}
                    onChange={handleLinkTypeChange}
                    allowDeselect={false}
                    miw={360}
                  />
                  {linkType === LinkType.CustomLink && (
                    <form onSubmit={form.onSubmit(handleSubmit)}>
                      <Stack>
                        <TextInput
                          label="Link Name"
                          placeholder='Title your link (eg: "Zoom")'
                          {...form.getInputProps('linkName')}
                        />
                        <TextInput label="URL Link" placeholder="https://zoom.us/..." {...form.getInputProps('link')} />
                        {(form.isDirty() || linkType !== initialPreferences.linkType) && (
                          <Button loading={isUpdatingPreferences} type="submit">
                            Save
                          </Button>
                        )}
                      </Stack>
                    </form>
                  )}
                  {linkType === LinkType.Zoom && (
                    <ZoomAccountSelector
                      initialZoomAccountId={initialPreferences.zoomAccountId}
                      onZoomAccountChange={handleZoomAccountChange}
                      isDirty={linkType !== initialPreferences.linkType}
                    />
                  )}
                </Stack>
              }
            />
            <Radio
              value={RemoteMeetingType.Phone}
              disabled={false}
              label={
                <>
                  <Text span lh={'normal'} mr={10}>
                    Phone call
                  </Text>
                  <Text span lh={'normal'} c="dimmed">
                    Blockit will ask the recipient for their phone number and add it to the location field.
                  </Text>
                </>
              }
            />
          </Radio.Group>
        </>
      }
    />
  );
};
