// Matches WorkingHourSettings defined in backend
// eslint-disable-next-line max-len
//https://github.com/Blockitons/blockit-server-v2/blob/6ddcec1c2dbcac8f791820a73dc9d0378a60a77e/src/services/workingHourService.ts

import { DayOfWeek, MeetingType, TimeOfDay } from '@/types';

export type WorkingHour = [TimeOfDay, TimeOfDay]; // Tuple with two TimeOfDay elements representing start and end times

export type WorkingHourSettings = {
  [day in DayOfWeek]?: WorkingHour[];
};

export enum LinkType {
  GoogleMeet = 'GoogleMeet',
  CustomLink = 'CustomLink',
  Zoom = 'Zoom',
}

export enum RemoteMeetingType {
  Video = 'video',
  Phone = 'phone',
}

export enum AvailabilityBlockType {
  AVAILABLE = 'available',
  NOT_PREFERRED = 'not_preferred',
}

export interface AvailabilityBlock {
  start: string;
  end: string;
  type: AvailabilityBlockType;
  meetingType?: MeetingType;
}

export type Availability = {
  [k in DayOfWeek]: AvailabilityBlock[];
};
export interface ConsecutiveMeetingMinutesBuffer {
  durationInMinutes: number;
  maxMeetingMinutes: number;
}

export interface PreferenceSettings {
  id?: string;
  duration: number;
  inPersonDuration: number;
  preferredStartingTimes: string[] | null;
  defaultRemoteMeetingType: RemoteMeetingType;
  linkType: LinkType;
  link?: string;
  linkName?: string;
  defaultAccountId?: string;
  availability: Availability | null;
  timeZone?: string;
  travelTimeBufferInMinutes: number;
  flightTravelTimeBufferInMinutes: number;
  consecutiveMeetingBuffer?: ConsecutiveMeetingMinutesBuffer;
  everyMeetingBufferInMinutes?: number;
  maxMeetingMinutesPerDay?: number;
  nudgeEnabled: boolean;
  numDaysToNudge: number;
  maxTimesToNudge: number;
  isDefault: boolean;
  zoomAccountId: string | null;
  workingHours: WorkingHourSettings | null;

  defaultSchedulingWindowStartInDays: number;
  defaultSchedulingWindowEndInDays: number;
}
