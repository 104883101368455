/**
 * This file is copied from blockit-server
 */
const TITLE_REGEX = /^(Dr|Dr\.|Professor|Prof\.|Mr\.|Mrs\.|Ms\.)$/i;

/**
 * Splits a full name into first and last name considering different formats.
 *
 * This function takes a full name string and splits it into first and last name.
 *
 * It checks if the name is in the format "<last name>, <first name>" and splits accordingly.
 * If the name is in the format "<first name> <last name>", it splits based on the space character.
 * If only one part is present, it assumes it is the first name.
 *
 * @param {string} fullName - The full name string to be split.
 * @returns {Object} An object containing `firstName` and `lastName` properties.
 */
export const getFirstAndLastName = (fullName: string): { firstName: string; lastName: string } => {
  let firstName = '';
  let lastName = '';

  // For empty name or not a human name (noreply), return empty.
  if (!fullName || fullName.toLowerCase().includes('noreply')) {
    return { firstName, lastName };
  }

  // Remove any leading or trailing whitespace
  fullName = fullName.trim();

  // Check if the name is in the format "<last name>, <first name>"
  if (fullName.includes(',')) {
    const parts = fullName
      .replace(/"/g, '')
      .split(',')
      .map((part) => part.trim());
    lastName = parts[0];
    firstName = parts.length > 1 ? parts[1] : '';
  } else {
    // Check for cases where the format is <title> <first name> <last name>
    const parts = fullName.split(' ').map((part) => part.trim());

    // Check if the first part is a title (e.g., Dr, Dr.)
    const hasTitle = TITLE_REGEX.test(parts[0]);

    if (hasTitle) {
      // If there's a title, shift the index for first and last name
      firstName = parts.length > 2 ? parts[1] : '';
      lastName = parts.length > 2 ? parts[parts.length - 1] : parts[1] || '';
    } else {
      // If no title, proceed as before
      if (parts.length > 1) {
        firstName = parts[0];
        lastName = parts[parts.length - 1];
      } else {
        // If only one part is present, assume it is the first name
        firstName = parts[0];
      }
    }
  }

  return { firstName, lastName };
};

/**
 * Get full name from the first and last name
 */
export const getFullName = (firstName: string, lastName?: string | null): string => {
  return `${firstName} ${lastName ?? ''}`.trim();
};

/**
 * Gets initials from first and last name
 * Returns null if both first name and last name are missing
 * Returns single initial if only one name exists
 */
export const getInitials = (firstName?: string | null, lastName?: string | null): string | null => {
  if (!firstName && !lastName) return null;
  const firstInitial = firstName ? firstName.charAt(0) : '';
  const lastInitial = lastName ? lastName.charAt(0) : '';
  return `${firstInitial}${lastInitial}`;
};
