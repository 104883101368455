import { FC, useState } from 'react';

import { Group, Select, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCalendarTime } from '@tabler/icons-react';

import { AnalyticsEventName, track } from '@features/analytics';

import { useUpdatePreferences } from '../api/updatePreferences';
import { PreferenceSettings } from '../types';

import { SettingsSectionTitle } from './SettingsSectionTitle';

interface DefaultSchedulingWindowProps {
  initialPreferences: PreferenceSettings;
}

const MAX_NUM_DAYS_FOR_SCHEDULING_WINDOW = 30;
const DAY_DROP_DOWN_OPTIONS = Array.from({ length: MAX_NUM_DAYS_FOR_SCHEDULING_WINDOW + 1 }, (_, i) => i.toString());

export const DefaultSchedulingWindow: FC<DefaultSchedulingWindowProps> = ({ initialPreferences }) => {
  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({
        type: AnalyticsEventName.PreferencesUpdated,
        data: { preference: 'schedulingWindow', isDefault: initialPreferences.isDefault },
      });
      notifications.show({ message: 'Scheduling window updated', color: 'green' });
    },
  });

  const [defaultWindowStart, setDefaultWindowStart] = useState(initialPreferences.defaultSchedulingWindowStartInDays);
  const [defaultWindowEnd, setDefaultWindowEnd] = useState(initialPreferences.defaultSchedulingWindowEndInDays);
  const [error, setError] = useState<string | null>(null);

  const handleStartDaysChange = (value: string | null) => {
    if (!value) return;
    const newWindowStartInDays = parseInt(value);

    if (newWindowStartInDays >= defaultWindowEnd) {
      setError('Start date must be before end date');
      return;
    }

    setError(null);
    setDefaultWindowStart(newWindowStartInDays);
    updatePreferences({
      ...initialPreferences,
      defaultSchedulingWindowStartInDays: newWindowStartInDays,
    });
  };

  const handleEndDaysChange = (value: string | null) => {
    if (!value) return;
    const newWindowEndInDays = parseInt(value);

    if (newWindowEndInDays <= defaultWindowStart) {
      setError('End date must be after start date');
      return;
    }

    setError(null);
    setDefaultWindowEnd(newWindowEndInDays);
    updatePreferences({
      ...initialPreferences,
      defaultSchedulingWindowEndInDays: newWindowEndInDays,
    });
  };

  return (
    <Stack>
      <Group>
        <SettingsSectionTitle
          title="Default Scheduling Window"
          Icon={IconCalendarTime}
          description="Set the default time range for proposing meeting times."
        />
      </Group>

      <span>
        <Text span>When unspecified, Blockit should propose times starting</Text>
        <Select
          display="inline-block"
          data={DAY_DROP_DOWN_OPTIONS}
          value={defaultWindowStart.toString()}
          onChange={handleStartDaysChange}
          allowDeselect={false}
          pl="xs"
          pr="xs"
          w={90}
        />
        <Text span>and ending</Text>
        <Select
          display="inline-block"
          data={DAY_DROP_DOWN_OPTIONS}
          value={defaultWindowEnd.toString()}
          onChange={handleEndDaysChange}
          allowDeselect={false}
          pl="xs"
          pr="xs"
          w={90}
        />
        <Text span>calendar days into the future.</Text>
      </span>
      {error && (
        <Text size="sm" c="red" mt={-8}>
          {error}
        </Text>
      )}
    </Stack>
  );
};
