import { FC } from 'react';
import { Text, ActionIcon } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { useDeleteAccount } from '../api/deleteAccount';
import { AccountWithCalendars } from '../types';

interface DeleteAccountButtonProps {
  account: AccountWithCalendars;
}

export const DeleteAccountButton: FC<DeleteAccountButtonProps> = ({ account }) => {
  const { mutate: deleteAccount } = useDeleteAccount();

  const openDeleteConfirmModal = () => {
    modals.openConfirmModal({
      title: 'Delete Account',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this account ({account.email})?
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        deleteAccount({ accountId: account.accountId });
      },
    });
  };

  return (
    <ActionIcon variant="transparent" color="red" onClick={openDeleteConfirmModal}>
      <IconTrash />
    </ActionIcon>
  );
};
