import { Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

interface InfoTooltipProps {
  description: string;
}

export const InfoTooltip = ({ description }: InfoTooltipProps) => {
  return (
    <Tooltip
      label={description}
      arrowSize={6}
      withArrow
      multiline
      w={300}
      styles={{
        tooltip: {
          whiteSpace: 'normal',
        },
      }}
    >
      <IconInfoCircle size=".8rem" />
    </Tooltip>
  );
};
