import { Box, Divider, Space, Stack } from '@mantine/core';

import { usePreferences } from '@features/preferences';

import { useCalendarsByAccounts } from '../api/getCalendars';

import { AccountDetails } from './AccountDetails';
import { ConnectCalendarButton } from './ConnectCalendarButton';
import { RevokedAccountDetails } from './RevokedAccountDetails';
import { AccountState } from '../types';

export const CalendarSettingsContainer = () => {
  const { data: accounts, isLoading: isLoadingAccounts } = useCalendarsByAccounts();
  const { data: preferences, isLoading: isLoadingPreferences } = usePreferences();

  if (!preferences || !accounts || isLoadingAccounts || isLoadingPreferences) {
    return null;
  }

  const sortedAccounts = accounts.sort((a, b) => a.email.localeCompare(b.email));
  return (
    <>
      <Stack>
        {sortedAccounts.map((account) => 
          account.state !== 'deleted' && (
            <Box key={account.email} pb="sm">
              {account.state === AccountState.ACTIVE && <AccountDetails account={account} />}
              {account.state === AccountState.REFRESH_TOKEN_FAILED && <RevokedAccountDetails account={account} />}
              <Space h="md" />
              <Divider />
            </Box>
          )
        )}

        <Box>
          <ConnectCalendarButton />
        </Box>
      </Stack>
    </>
  );
};
