import { IconCalendar, IconClock12, IconCode } from '@tabler/icons-react';

import { CodewordsPage } from './CodewordsPage';
import { PreferredBlocksPage } from './PreferredBlocksPage';
import { SettingsPage } from './SettingsPage';

export const UPDATE_NAV_LINKS = [
  {
    label: 'Codewords',
    description: 'Name and codewords for this template',
    Icon: IconCode,
    relativePath: 'codewords',
    element: <CodewordsPage />,
  },
  {
    label: 'Preferences',
    description: 'Custom preferences for this template',
    Icon: IconClock12,
    relativePath: 'preferences',
    element: <SettingsPage />,
  },
  {
    label: 'Availability',
    description: 'Custom availability for this template',
    Icon: IconCalendar,
    relativePath: 'availability',
    element: <PreferredBlocksPage />,
  },
];
