import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { NotificationSettings } from '../types';

const upsertNotificationSettings = async (settings: NotificationSettings): Promise<NotificationSettings> => {
  const { data } = await axios.put('/notification-settings', settings);
  return data;
};

export const useUpsertNotificationSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: upsertNotificationSettings,
    onSuccess: () => {
      notifications.show({
        title: 'Notification settings updated',
        message: 'Your notification settings have been updated successfully.',
        color: 'green',
      });
      queryClient.invalidateQueries({ queryKey: ['notification-settings'] });
    },
  });
};
