import { FC } from 'react';

import { Group, Stack, Text, Title } from '@mantine/core';
import { Icon } from '@tabler/icons-react';

interface SectionHeaderProps {
  icon: Icon;
  title: string;
  description: string;
}

export const SectionHeader: FC<SectionHeaderProps> = ({ icon: IconComponent, title, description }) => {
  return (
    <Stack gap={4}>
      <Title order={3} size="h4" fw={600}>
        <Group gap="xs" w="100%">
          <IconComponent size={20} />
          <Text>{title}</Text>
        </Group>
      </Title>
      <Text size="sm" c="dimmed">
        {description}
      </Text>
    </Stack>
  );
};
