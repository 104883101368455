import { FC } from 'react';

import { Group, Select, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { InfoTooltip } from '@components/InfoTooltip';
import { Switch } from '@components/Switch';
import { AnalyticsEventName, track } from '@features/analytics';
import { useCalendarsByAccounts } from '@features/calendar-selection';
import { usePreferences, useUpdatePreferences } from '@features/preferences';

import { useNotificationSettings, useUpsertNotificationSettings } from '../api';
import type { NotificationSettings } from '../types';

export const SettingsForm: FC = () => {
  const { data: settings } = useNotificationSettings();
  const { mutate: updateSettings } = useUpsertNotificationSettings();
  const { data: preferences } = usePreferences();
  const { mutate: updatePreferences, isPending: isUpdatingPreferences } = useUpdatePreferences({
    onSuccess: () => {
      notifications.show({
        title: 'Preferred email updated',
        message: 'Your preferred email has been updated',
        color: 'green',
      });
      track({
        type: AnalyticsEventName.NotificationSettingsUpdated,
        data: {
          setting: 'preferredEmail',
        },
      });
    },
  });
  const { data: accounts } = useCalendarsByAccounts();

  const handleToggle = (field: keyof NotificationSettings) => (checked: boolean) => {
    if (!settings) return;

    updateSettings({
      ...settings,
      [field]: checked,
    });
    track({
      type: AnalyticsEventName.NotificationSettingsUpdated,
      data: {
        setting: field,
      },
    });
  };

  if (!settings || !preferences || !accounts) return null;

  const handleDefaultAccountChange = (value: string | null) => {
    if (!value) {
      return;
    }

    updatePreferences({ ...preferences, defaultAccountId: value });
  };

  return (
    <Stack gap="xl">
      <Select
        label={
          <Group gap="xs">
            Preferred Email
            <InfoTooltip
              description="Choose which email address will receive notifications
                like daily digests and travel detection notifications. This email will be used as your primary contact
                for all outbound communications."
            />
          </Group>
        }
        description="Select the email address you'd like Blockit to use for outbound notifications"
        data={accounts.map((account) => account.email)}
        value={preferences.defaultAccountId}
        onChange={handleDefaultAccountChange}
        disabled={isUpdatingPreferences}
        allowDeselect={false}
        size="md"
        w={500}
      />

      <Switch
        checked={settings.digestEmailEnabled}
        onChange={(event) => handleToggle('digestEmailEnabled')(event.currentTarget.checked)}
        label={
          <Group gap="xs">
            Daily Digest Email
            <InfoTooltip
              description="Receive an evening summary of your next day's meetings with attendees. 
                This helps you prepare for upcoming discussions and review who you'll be meeting with."
            />
          </Group>
        }
        description="Get a daily email summary of tomorrow's meetings"
        size="md"
      />

      <Switch
        checked={settings.tripsEmailEnabled}
        onChange={(event) => handleToggle('tripsEmailEnabled')(event.currentTarget.checked)}
        label={
          <Group gap="xs">
            Travel Detection Notifications
            <InfoTooltip
              description={
                'Get notified when we detect travel plans in your calendar. This allows you to ' +
                'confirm travel so that we can automatically adjust scheduling based on different ' +
                'time zones and locations, ensuring meetings are set at appropriate times and ' +
                'locations during your travels.'
              }
            />
          </Group>
        }
        description="Receive notifications when travel plans are detected in your calendar"
        size="md"
      />

      <Switch
        checked={settings.privacyModeEnabled}
        onChange={(event) => handleToggle('privacyModeEnabled')(event.currentTarget.checked)}
        label={
          <Group gap="xs">
            Enhanced Privacy Mode
            <InfoTooltip
              description={
                "Increases privacy by displaying 'private' in digests instead of details for events " +
                'from non-primary calendars or events that are marked as private.'
              }
            />
          </Group>
        }
        description="Control if non-primary calendar event details are sent over email"
        size="md"
      />
    </Stack>
  );
};
