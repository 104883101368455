import { FC } from 'react';

import { Box, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconCalendarOff } from '@tabler/icons-react';

import { AccountWithCalendars } from '../types';
import { DeleteAccountButton } from './DeleteAccountButton';

interface AccountDetailsProps {
  account: AccountWithCalendars;
}

export const RevokedAccountDetails: FC<AccountDetailsProps> = ({ account }) => {
  return (
    <Stack>
      <Group gap="xs">
        <Group display="flex" justify="space-between" w="100%">
          <ThemeIcon color="red" variant="transparent">
            <IconCalendarOff />
          </ThemeIcon>
          <DeleteAccountButton account={account} />
        </Group>
        <Text key={account.email}>{account.email}</Text>
      </Group>
      <Box ml={38}>
        <Text size="sm" fs="italic">
          We no longer have access to this account. Please add calendar again to grant access.
        </Text>
      </Box>
    </Stack>
  );
};
