import { useEffect, useState } from 'react';

import { Button, Menu, Radio, Stack } from '@mantine/core';
import { IconClock } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QUERY_KEY } from '@features/codeword-templates/api/constants';
import { useCodewordTemplate } from '@features/codeword-templates/api/getCodewordTemplate';
import {
  Availability,
  AvailabilityCalendar,
  getEmptyAvailability,
  usePreferences,
  useUpdatePreferences,
  WorkingHoursForm,
} from '@features/preferences';
import { WorkingHourSettings } from '@features/preferences/types';

export const PreferredBlocksPage = () => {
  const [selectedValue, setSelectedValue] = useState<string>('default');
  const [availabilityDraft, setAvailabilityDraft] = useState<Availability | null>(null);
  const [workingHoursDraft, setWorkingHoursDraft] = useState<WorkingHourSettings | null>(null);
  const { data: defaultPreferences } = usePreferences();

  const { id } = useParams();
  const { data: codewordTemplate } = useCodewordTemplate(id);
  const queryClient = useQueryClient();
  const { mutate: updatePreferences, isPending: isUpdating } = useUpdatePreferences({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY, id] });
    },
  });

  useEffect(() => {
    // Setting availability draft so that we don't lose the user's changes when we set selected value to default
    if (!availabilityDraft && codewordTemplate && defaultPreferences) {
      if (codewordTemplate.preferenceSettings.availability) {
        setSelectedValue('custom');
        setAvailabilityDraft(codewordTemplate.preferenceSettings.availability);
        setWorkingHoursDraft(codewordTemplate.preferenceSettings.workingHours);
      } else {
        setSelectedValue('default');
        setAvailabilityDraft(getEmptyAvailability());
        setWorkingHoursDraft(defaultPreferences.workingHours);
      }
    }
  }, [codewordTemplate?.preferenceSettings.availability, availabilityDraft, codewordTemplate, defaultPreferences]);

  if (!codewordTemplate || !availabilityDraft || !defaultPreferences) {
    return null;
  }

  const updateAvailability = (availability: Availability | null, callback?: () => void) => {
    if (availability) {
      setAvailabilityDraft(availability);
    }
    updatePreferences({ ...codewordTemplate.preferenceSettings, availability }, { onSuccess: callback });
  };

  const updateAvailabilityAndWorkingHours = (
    availability: Availability | null,
    workingHours: WorkingHourSettings | null,
  ) => {
    if (availability) {
      setAvailabilityDraft(availability);
    }
    if (workingHours) {
      setWorkingHoursDraft(workingHours);
    }
    updatePreferences({ ...codewordTemplate.preferenceSettings, availability, workingHours });
  };

  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
    if (value === 'default') {
      updateAvailabilityAndWorkingHours(null, null);
    } else {
      updateAvailabilityAndWorkingHours(availabilityDraft, workingHoursDraft);
    }
  };

  return (
    <>
      <Radio.Group
        label="Availability"
        description="Use default availability or add custom availability for this template"
        defaultValue="default"
        value={selectedValue}
        onChange={handleRadioChange}
        mb="md"
      >
        <Stack gap="xs" mt="xs">
          <Radio value="default" label="Use default availability" />
          <Radio value="custom" label="Use custom availability" />
        </Stack>
      </Radio.Group>

      {selectedValue === 'custom' && (
        <>
          <Menu position="bottom-start">
            <Menu.Target>
              <Button leftSection={<IconClock size={14} />} variant="light" mb="md" size="xs">
                Configure Meeting Hours
              </Button>
            </Menu.Target>
            <Menu.Dropdown p="lg">
              <WorkingHoursForm
                preferences={codewordTemplate.preferenceSettings}
                onUpdate={(newWorkingHours: WorkingHourSettings | null) => {
                  queryClient.invalidateQueries({ queryKey: [QUERY_KEY, id] });
                  setWorkingHoursDraft(newWorkingHours);
                }}
              />
            </Menu.Dropdown>
          </Menu>
          <AvailabilityCalendar
            initialAvailability={availabilityDraft}
            preferences={codewordTemplate.preferenceSettings}
            updateAvailability={updateAvailability}
            isUpdating={isUpdating}
          />
        </>
      )}
    </>
  );
};
